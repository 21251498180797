@charset "utf-8";

// Define defaults for each variable.

$base-font-family: 'IBM Plex Mono', monospace;
$base-font-size: 18px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.85 !default;
$base-line-height: 1.8 !default;
$small-line-height: 1.6 !default;

$spacing-unit: 30px !default;

$text-color: #111 !default;
$background-color: #fdfdfd !default;
$brand-color: #2a7ae2 !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;
$orange-color: #f66a0a !default;
$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

$on-medium: $on-palm !default;
$on-large: $on-laptop !default;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import "moving/base", "moving/layout";
